import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ paddingTop: '15px', maxWidth: `750px`, marginBottom: `0.5rem` }}>
      <Image />
    </div>
    <p>Welcome to GuruKripa, an ex-army officer managed guesthouse in Newtown, Kolkata.</p>
    <p>Our guesthouse offers you a relaxed homestay away from home. Conveniently located within a 30 minute drive from the airport, 
      with a host of nearby attractions as well as special activities like golf lessons and yoga classes. GuruKripa is at a close proximity to major IT companies
      and hospitals. Checkout the <Link to="/activity/">Nearby</Link> page for more information.
    </p>
    <p><Link to="/contact/">Contact us</Link> today for your next visit to The City of Joy!</p>
       
  </Layout>
)

export default IndexPage
